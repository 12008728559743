/*
* 업무구분: 디지털프로필 > 관리 > POP
* 화면 명: MSPBC106P
* 화면 설명: 전문서비스 등록/수정
*/
<template>
  <ur-page-container class="msp" type="popup" :show-title="false" title="전문서비스" :topButton="true">
      <template v-slot:title class="">{{title}}</template>
      <div class="ns-btn-close" @click="fn_CancelPop" name="닫기"></div>

      <ur-box-container alignV="start" componentid="ur_box_container_010" direction="row" class="fexTy3  pb16 pl24 pr24 bdTN expand_bottomline fs16rem">
        <span><b><em class="em_normal--2 crTy-blue3">{{selCnt}}</em>개선택</b>(최대 6개)</span>
        <div class="fexTy1" @click="fn_isSelShow()">
          <span>선택보기</span>
          <mo-switch v-model="isSelShow" small class="ns-switch ml10"/>
        </div>
      </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-bottom-sheet">
      <div class="full home_profile--bs">
    <!--  바텀 시트 컨텐츠 추가 영역 시작 -->
    <div class="content-area">

      <ur-box-container v-show=(!isSelShow) alignV="start" componentid="" direction="column">
        <div v-for='(items,idx1) in lv_SerList' :key='idx1' class="full">
          <ur-box-container alignV="start" componentid="" direction="column">
            <p class="fs18rem fwb mb10 mt20">{{items.cdNm}}</p>
            <ur-box-container alignV="start" componentid="" direction="column" class="img_list_pro--box">     
                <div v-for="item in items.datalist" :key='item.profHyptSn' :class="(item.no % 2 !== 0)? 'item item-green full fexTy2' : 'item full fexTy2'" @click="fn_selItem(items.profHyptGrpCd+item.profHyptSn)" >    
                    <div class="ns-radio-list flex maxW40">
                      <mo-checkbox v-model="itemSellist" :value="items.profHyptGrpCd+item.profHyptSn" ></mo-checkbox>
                    </div>
                    <div class="txt_box ml0">
                      <span class="" v-html="item.profHyptTitl" ></span>
                    </div>
                </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </ur-box-container>

      <ur-box-container v-show='(isSelShow) && (itemSellist.length > 0)' ur-box-container alignV="start" componentid="" direction="column">
        <ur-box-container alignV="start" componentid="" direction="column">
          <ur-box-container alignV="start" componentid="" direction="column" class="img_list_pro--box">  
            <div v-for="items in itemSellist" :key='items.profHyptSn' class="item full fexTy2" @click="fn_selItem(items)" >
              <div class="ns-radio-list flex maxW40">
                <mo-checkbox v-model="itemSellist" :value="items"></mo-checkbox>
              </div>
              <div class="txt_box ml0">
                <span class="" v-html="fn_getGrpSnTitle(items)" ></span> 
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container v-show='(isSelShow) && (itemSellist.length === 0)' alignV="start" componentid="" direction="column" class="home_profile_nodata content-center">
        <span class="align_self-center mt60" >선택된 항목이 없습니다.</span>
      </ur-box-container>
    </div>
    <!-- 바텀 시트 컨텐츠 추가 영역 끝-->
      </div>
    </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelPop">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CallSave">저장</mo-button>
          </div>
        </ur-box-container>
  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import bcConstants from '@/config/constants/bcConstants.js'

  export default {
    name: "MSPBC106P",
    screenId: "MSPBC106P",
    components: { },
    data() {
      return {
        P_params: {}
        , lv_SerList: bcConstants.BC_SVC_LIST   // 고객서비스 기준정보 List
        , defArr: bcConstants.BC_SVC_LIST
        , itemChecked: []  // 기준정보 그룹목록 체크 arrays
        , itemSellist: []
        , isSelShow: false
      };
    },
    modalProps: {
      full: false
    },
    props: {
      title: {
        type: String,
        default: "전문서비스"
      },
      params: {
        type: Object,
        default: {}
      }
    },
    created() {
      this.fn_setMapData()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기      
    },
    computed: {
      selCnt: function(){        
        let ic = this.itemSellist.length
        if(ic > 6){
            this.getStore('toast').dispatch('ADD', '6개까지 등록가능합니다.')
            this.itemSellist.splice(-1,1)
            ic = ic-1
          }
        return ic
      }
    },
    methods: {
      fn_isSelShow(){
        this.isSelShow = (this.isSelShow == true)?false:true
      },
      fn_selItem(val){
        // console.log('>>>>>>>>>val='+val)
        const data = this.itemSellist.find( item => item === val)
        // console.log('>>>>>>>>>data='+data)
        if(data === undefined){
          this.itemSellist.push(val)
        }else{
          let removeIdx = this.itemSellist.findIndex( item => item === val)
          this.itemSellist.splice(removeIdx,1)
        }
      },
      fn_ConfirmPop() {
        this.$emit("initDataPopup", "C03");
      },
      fn_CancelPop() {
        // console.log(this.params)
        this.$emit("cancelPopup");
      }, 
      fn_setMapData(){
        // 1. 기준정보 그룹목록 초기화 작업 
        for(let i=0; i < this.lv_SerList.length; i++){
          let arr = []
          this.itemChecked.push(arr)          
        }
        // 2. 설정저장된 값 매핑
        // console.log(">>>>>>>>params.datalist.length:"+this.params.datalist.length)
        this.P_params = this.params.datalist  // grplist
        for(let y=0; y < this.P_params.length; y++){
          let arr = []
          let val = {}
          let hyptGrpCd = this.P_params[y].profHyptGrpCd        
          let grpIdx = hyptGrpCd.substr(4)

          for(let i=0; i < this.P_params[y].datalist.length; i++){          
            val = hyptGrpCd + this.P_params[y].datalist[i].profHyptSn
            arr.push(val) //  
            this.itemSellist.push(val)
          }
          this.itemChecked[grpIdx-1] = arr
        }      
      },   
      /* SN에 대응하는 title 가져오기 (단일건 처리)
         _grpSn : profHyptGrpCd + profHyptSn
      */
      fn_getGrpSnTitle(_grpSn){
        if(_grpSn == null || _grpSn.length < 6){  return '' }
        const defArr = this.defArr
        const grpCd = _grpSn.substr(0,5)
        const sn = _grpSn.substr(5,6)
        const grpT = defArr.find( item => item.profHyptGrpCd === grpCd)
        // console.log('grpT:'+grpT)
        if(grpT == undefined){  return '' }
        let snT = grpT.datalist.find( item => item.profHyptSn === Number(sn))
        // console.log('snT:'+snT)
        if(snT == undefined){ return '' }
          return snT.profHyptTitl
      }, 
      fn_CallSave(){ // 고객서비스 설정선택 등록/저장 요청 
        let lv_Vm = this
        const trnstId = 'txTSSBC10U7' // 설정코드별 매핑리스트저장
        const auth = 'S'
        let pParams = { profHyptCd: 'C03', grpList: [] }
        let seti = 0
      // console.log('this.itemSellist:'+this.itemSellist)
          if(this.itemSellist == null || this.itemSellist.length == undefined){
            console.log('itemSellist undefined!!!!!!!!!!!!!!!!!')
          }else{
            for(let i=0; i < this.itemSellist.length; i++){
              let grpCd = this.itemSellist[i].substr(0,5)
              //  console.log('grpCd:'+grpCd)
              let setGrpCd = {profHyptGrpCd:grpCd,datalist:[]}
              pParams.grpList.push(setGrpCd)
              let sn = this.itemSellist[i].substr(5,6)          
              let setSn = {profHyptSn:sn}
              pParams.grpList[seti].datalist.push(setSn)
              // console.log('grpCd:'+grpCd+', sn:'+sn)
              seti++
            }
          }
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            const Iv_ProfSVO = response.body             
            // console.log(Iv_ProfSVO.userEno);
            lv_Vm.getStore('toast').dispatch('ADD', '전문서비스가 설정되었습니다.')
            lv_Vm.fn_ConfirmPop()
          })
          .catch(function (error) {
            window.vue.error(error)
            lv_Vm.fn_CancelPop()
          })
      } // end fn_CallSave
    }
  };
</script>