/*
* 업무구분: 디지털프로필 > 관리 > POP
* 화면 명: MSPBC107P
* 화면 설명: 한줄소개 등록/수정
*/
<template>
  <ur-page-container class="msp" type="popup" :show-title="true" title="한줄 소개" :topButton="true">
      <template v-slot:title class="">{{title}}</template>
      <div class="ns-btn-close" @click="fn_CancelPop" name="닫기"></div>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-bottom-sheet">
      <div class="full home_profile--bs">
      <!--  바텀 시트 컨텐츠 추가 영역 시작 -->
        <div class="content-area">
          <div class="ns-radio-list flex" @click="fn_radioSelect('N')">
            <mo-radio v-model="radioSelect" value="N" class="bgwhite hauto mhAuto" ><span v-html="this.def_title" class="pt4 pb4"></span></mo-radio>
          </div>          
          <div class="user_info_message mt20"  @click="fn_radioSelect('Y')">
            <div class="ns-radio-list flex">
              <mo-radio v-model="radioSelect" value="Y" class="bgwhite"></mo-radio>
            </div>
            <mo-text-area v-model="textarea" maxlength="30" class="hauto pa12 crTy-bk1" rows="3"  placeholder="한 줄 소개를 입력해주세요." @input="fn_changeTxt" @focus="fn_ControlKeyPad" ref="tarea" :disabled="radioSelect=='N'" />
          </div>
          <ur-box-container direction="column" align-v="start" class="ns-info-area">
            <ur-box-container alignV="start" componentid="" direction="column" class="info-box pal0">
              <ur-box-container alignV="start" componentid="" direction="row" class="fexJsCtTy1">
                <ul class="terms-list-area--gray mt10 ml40">
                  <li class="red">
                    최대 30자, 한 줄에 15자
                  </li>
                  <li class="mt4">
                    과장/허위사실 기재 금지
                  </li>
                </ul>
                <div class="user_info_message--cnt mt6">
                  <span><em>{{textarea.length}}</em> / 30</span>
                </div>
              </ur-box-container>
            </ur-box-container>
          </ur-box-container>
        </div>
      <!-- 바텀 시트 컨텐츠 추가 영역 끝-->
      </div>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelPop">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CallSave">저장</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
import bcConstants from '@/config/constants/bcConstants.js'

  export default {
    name: "MSPBC107P",
    screenId: "MSPBC107P",
    components: { },
    data() {
      return {
        foreignCustVo: {}
        ,radioSelect:'N'
        ,textarea:''
        ,P_params:{}
        ,def_title: bcConstants.BC_HYPT_DEF_TITL
      }
    },
    modalProps: {
      full: false
    },
    props: {
      title: {
        type: String,
        default: "한줄소개"
      },
      params: {
        type: Object,
        default: {}
      }
    },
    created() {      
      this.P_params = this.params
      this.radioSelect = this.P_params.hyptCdYn
      if(this.radioSelect == 'Y'){
        this.textarea = this.P_params.profHyptTitl         
      }
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기 
      this.fn_setTxtFocus()
    },
    computed: {    
    },
    watch: {
    },
    methods: {
      fn_radioSelect(_is){
        this.radioSelect = _is
      },
      fn_ConfirmPop() {
        this.$emit("initDataPopup", "C00");
      },
      fn_CancelPop() {
        console.log(this.params)
        this.$emit("cancelPopup");
      },
      fn_setTxtFocus() {
        console.log(this.radioSelect)
        if(this.radioSelect == 'Y'){
          this.$refs.tarea.focus()     
        }
      },
      fn_CallSave(){ // 한줄소개 등록/저장 요청 
      let lv_Vm = this
      let text = lv_Vm.textarea.trim()
      // console.log('>>>>>>' + text.length)
       if((lv_Vm.radioSelect == 'Y') && (text.length < 1)){
          lv_Vm.getStore('toast').dispatch('ADD', '한줄 소개는 필수입력항목입니다.')
          this.textarea = text
          return 
        }
        //else{        
          const trnstId = 'txTSSBC10U5'
          const auth = 'S'
          let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId,profHyptInfoList:[{profHyptCdUseYn:'',grpList:[{datalist:[{profHyptTitl:' '}]}]}]}
              pParams.profHyptInfoList[0].profHyptCdUseYn = this.radioSelect
              pParams.profHyptInfoList[0].grpList[0].datalist[0].profHyptTitl = this.textarea
              if(this.radioSelect == 'N'){
                pParams.profHyptInfoList[0].grpList[0].datalist[0].profHyptTitl = ' '
              }
          this.post(lv_Vm, pParams, trnstId, auth)
            .then(function (response) {
              const Iv_ProfSVO = response.body               
              // console.log(Iv_ProfSVO.userEno);
              lv_Vm.getStore('toast').dispatch('ADD', '프로필이 설정되었습니다.')
              lv_Vm.fn_ConfirmPop()
            })
            .catch(function (error) {
              window.vue.error(error)
              lv_Vm.fn_CancelPop()
            })
        //}
      },
      fn_changeTxt(){
        let textl = this.textarea.length
        if( (textl > 30)){
          this.getStore('toast').dispatch('ADD', '30자까지 입력가능합니다.')
          this.textarea = this.textarea.substr(0,30)
        }       
      },
      /******************************************************************************
       * Function명 : fn_ControlKeyPad
       * 설명       : 키보드 입력 시 화면을 키보드 위로 올라오게 처리하는 함수
       ******************************************************************************/
      fn_ControlKeyPad () {
        this.$commonUtil.setHiddenPlugin(false)
      }
    }    
  }
</script>