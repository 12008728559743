/*
* 업무구분: 디지털프로필
* 화면 명: MSPBC103P
* 화면 설명: 프로필 사진 설정
*/
<template>
   <ur-page-container class="msp" type="popup" :show-title="true" title="프로필 사진 설정" :topButton="true">
     <template v-slot:title class="">프로필 사진 설정</template>
     <div class="ns-btn-close" name="닫기"></div>
     <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-bottom-sheet">
       <div class="content-area home_profile--bs full">
        <p class="mt0 mb30">선택된 사진은 디지털 프로필과 안내자료 기사 인쇄 시 반영됩니다.</p>
        <div class="profile_box_wrap">
          <div class="photo_wrap">
            <img v-bind:src="profileImg" ref="profileImgRef" alt="" class="profile_img"> <!-- 미리보기 -->
            <mo-button class="photo_add" @click="fn_ChangeImg('sel')">사진등록</mo-button>
          </div>
        </div>
        <span class="comp_spacing--h"></span>
        <div class="btn_group">
          <!-- 개인 설정 이미지가 없는 경우 시작-->
          <div class="none_image" v-if="!selProfImgBtn">
            <mo-button class="btn_profile_add" @click="fn_ChangeImg('sel')"><mo-icon icon="add">add</mo-icon></mo-button>
          </div>
          <!-- 개인 설정 이미지가 있는 경우 -->
          <div class="btn_preview" v-else>
            <img v-bind:src="profileImgBtn" @click="fn_SelImg('00')" alt="" class="preview_img">
            <mo-button class="btn_del" @click="fn_ChangeImg('del')"><mo-icon icon="close">close</mo-icon></mo-button>
          </div>
          <div class="btn_preview">
            <img src="@/assets/img/profile/ico_life.png" alt="" class="preview_img" @click="fn_SelImg('02')">
          </div>
          <div class="btn_preview">
            <img src="@/assets/img/profile/ico_pro.png" alt="" class="preview_img" @click="fn_SelImg('03')">
          </div>
          <div class="btn_preview">
            <img src="@/assets/img/profile/ico_character.png" alt="" class="preview_img" @click="fn_SelImg('04')">
          </div>
        </div>
      </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div" v-if="baseProfImgBtn">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ConfirmPop('cancle')">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmPop('confirm')">저장</mo-button>
          </div>
          <div class="relative-div" v-else>
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmPop('user')">확인</mo-button>
          </div>
        </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import localImg from '@/ui/cm/test'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC103P",
    screenId: "MSPBC103P",
    components: {
    },
    props: {
      params: {}
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        value1: true,
        value2: true,
        lv_AlertPop: {},
        alertBtnSingle: false,
        selProfImgBtn: false,
        baseProfImgBtn: true,
        isShowImg: true,
        alertTitle: '',
        alertContens:'',
        retEnData: '',
        profileImg: require('@/assets/img/profile/ico_life.png'),
        profileImgBtn: '',
        baseImg02: require('@/assets/img/profile/ico_life.png'),
        baseImg03: require('@/assets/img/profile/ico_pro.png'),
        baseImg04: require('@/assets/img/profile/ico_character.png'),
        lv_ImgSelectedYn: 'N', // 이미지 저장이 되었는지 여부
        sBaseImgType: '02', // 이미지 선택형태 01=내사진, 02=기본사진Type1, 03=기본사진Type2
        lv_SelImgFileUrl: '',
        PROFILE_CNSLT_IMG_DIR: 'D:\\', // 서버 다운로드 Property 정보
        lv_ImgName: this.getStore('userInfo').getters.getUserInfo.userId
      }
    },
    mounted () {
      this.$bizUtil.insSrnLog('MSPBC103P')
      this.$nextTick( () => { // DOM의 상태가 변경 될 때 처리
        if (!this.$bizUtil.isEmpty(this.$bizUtil.digitProfImg)) { // 사용자지정 프로필 사진 있을 경우
          console.log('### MSPBC103P DIGIT PROFILE 있음 ###')
          if (!this.$bizUtil.isEmpty(this.params.profImgBaseType)) { // 저장된 사진이 기본 이미지일 경우
            this.profileImg = this[`baseImg${this.params.profImgBaseType}`]
            this.selProfImgBtn = false
            this.baseProfImgBtn = true
          } else {
            // this.profileImg = this.$bizUtil.digitProfImg
            // this.profileImgBtn= this.$bizUtil.digitProfImg
            let imgUrl = this.fn_getImgUrl()
            let timeStamp = moment(new Date().toISOString()).format('YYYYMMDDHHmmss').toString().split('').reverse().join('')
            this.profileImg = imgUrl + this.lv_ImgName + '&sno=' + timeStamp
            this.profileImgBtn = imgUrl + this.lv_ImgName + '&sno=' + timeStamp
            this.selProfImgBtn = true
            this.baseProfImgBtn = false
          }
        }

        if (process.env.NODE_ENV === 'local') {
          if (!this.$bizUtil.isEmpty(this.params.profImgBaseType)) {
            this.profileImg = this[`baseImg${this.params.profImgBaseType}`]
            this.selProfImgBtn = false
            this.baseProfImgBtn = true
          } else {
            // this.profileImg = `data:image/jpeg;base64, ${localImg.lv_test}`
            // this.profileImgBtn = `data:image/jpeg;base64, ${localImg.lv_test}`
            let imgUrl = this.fn_getImgUrl()
            let timeStamp = moment(new Date().toISOString()).format('YYYYMMDDHHmmss').toString().split('').reverse().join('')
            this.profileImg = imgUrl + this.lv_ImgName + '&sno=' + timeStamp
            this.profileImgBtn = imgUrl + this.lv_ImgName + '&sno=' + timeStamp
            this.selProfImgBtn = true
            this.baseProfImgBtn = false
          }
        }
      })
      let userEno = this.getStore('userInfo').getters.getUserInfo.userEno
      this.fn_getEncryptCall(userEno)
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_getImgUrl () {
        let imgUrl = '/profimgdown.do?userEno='
        if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
          let locHost = location.host
          if (locHost.indexOf('imss') > -1) {
            imgUrl = 'https://itss.samsunglife.com:8410' + imgUrl // 임시로 우회처리
          }
        }
        return imgUrl
      },
      /******************************************************************************
      * Function명 : fn_BottomAlert
      * 설명       : 알림 팝업 호출
      ******************************************************************************/
    fn_BottomAlert (type) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: this.alertBtnSingle,
          title: this.alertTitle,
          content: this.alertContens,
          title_pos_btn: (type === 'sel') ? '확인' : '삭제'
        },
        listeners: {
          onPopupConfirm: () => {
            if (type === 'sel') { // 사진변경
              // this.fn_UpdUserBaseImg()
              if (process.env.NODE_ENV === 'local') { // 업로드 후 재조회 됐다 치고..
                this.profileImg = `data:image/jpeg;base64, ${localImg.lv_test}`
                this.profileImgBtn = `data:image/jpeg;base64, ${localImg.lv_test}`
                this.selProfImgBtn = true
                this.baseProfImgBtn = false
              } else {
                this.fn_SelImgFile()
              }
            } else { // 사진 삭제
              this.sBaseImgType = '02'
              this.profileImg = this.baseImg02
              this.selProfImgBtn = false
              this.baseProfImgBtn = true
            }
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_SelImg
      * 설명       : 기본이미지 선택 (type1: '02', type2: '03')
      * Return     :
      ******************************************************************************/
      fn_SelImg (param) {
        if (param === '00') {
          this.profileImg = this.profileImgBtn
          this.baseProfImgBtn = false
        } else {
          this.sBaseImgType = param
          this.profileImg = this[`baseImg${param}`]
          this.baseProfImgBtn = true
        }
      },
      /******************************************************************************
      * Function명 : fn_ChangeImg
      * 설명       : 사진 변경/추가
      ******************************************************************************/
      fn_ChangeImg (type) {
        if (type === 'sel') {
          this.alertBtnSingle = true
          this.alertTitle = '사진 업로드 시 유의사항'
          this.alertContens = '미승인 상품자료, 교육자료, 동의 안된 타인이 포함된 사진, 개인정보가 노출된 자료 등<br/> <strong style="color: #e2351f;">컴플라이언스에 위배되는 사진</strong>을 게시하여 문제가 발생하는 경우, <strong style="color: #e2351f;">컨설턴트의 책임</strong>이 따르므로 주의하시기 바랍니다.'
        } else {
          this.alertBtnSingle = false
          this.alertTitle = '프로필 사진 삭제'
          this.alertContens = '사진을 삭제하시겠습니까?'
        }
        this.fn_BottomAlert(type)
      },
      async fn_getEncryptCall(_string) {
        let strlist = [_string]
        let enData =  await this.$commonUtil.getEncryptCall(this,strlist)
        this.retEnData = enData[0]
      },
     /******************************************************************************
      * Function명 : fn_SelImgFile
      * 설명       : 이미지 선택 이벤트
      * Return     : void
      ******************************************************************************/
      fn_SelImgFile () {
        let lv_Vm = this
        let userEno = this.getStore('userInfo').getters.getUserInfo.userEno

        if (this.$commonUtil.isMobile() && this.$commonUtil.getIsIOS()) { // ios일때는 암호화
          userEno = this.retEnData
          console.log('암호화된 사번', this.retEnData)
        }

        let pParam = {
          path: 'PROFILE_CNSLT_IMG_DIR', // 이미지 업로드 경로-프로퍼티 Key
          rateX: 1,
          rateY: 1,
          filename: userEno // 저장파일명
        }
        
        window.vue.getStore('progress').dispatch('UPDATE', true)
        window.fdpbridge.exec('uploadImagePlugin', pParam, function (result) {
          if (result.data === '200' || result.data === 'ok') {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            lv_Vm.fn_CallSaImageCreate() // 안내자료 컨설턴트 이미지생성 서비스 호출
            lv_Vm.fn_UpdUserBaseImg() // DB 기본 이미지 정보 초기화
          } else {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            lv_Vm.getStore('toast').dispatch('ADD', '사진 업로드에 실패했습니다.')
          }
        }, function (result) {
          window.vue.getStore('progress').dispatch('UPDATE', false)
          console.log(result)
          if ( result.data === 'NOPERMISSION' ) {
            lv_Vm.getStore('toast').dispatch('ADD', '태블릿 내부 저장권한이 없습니다. 모바일영업시스템 초기구동시 권한설정에 동의 해주세요')
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_CallSaImageCreate
      * 설명       : 사용자 이미지 업로드 완료시  안내자료, 컨설턴트이미지 생성 서비스 호출
      * Return     :
      ******************************************************************************/
      fn_CallSaImageCreate () {
        let lv_Vm = this
        let pParams = {cnsltNo: this.lv_ImgName}
        const trnstId = 'txTSSSA22P5'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) {
              // [디지털 프로필] 이미지 조회
              lv_Vm.$bizUtil.selDigitProfImg().then(() => {
                if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.$bizUtil.digitProfImg)) {
                  console.log('#### MSPBC103P 이미지 재조회 완료 #### ')
                  // lv_Vm.profileImg = lv_Vm.$bizUtil.digitProfImg
                  // lv_Vm.profileImgBtn = lv_Vm.$bizUtil.digitProfImg
                  let imgUrl = lv_Vm.fn_getImgUrl()
                  let timeStamp = moment(new Date().toISOString()).format('YYYYMMDDHHmmss').toString().split('').reverse().join('')
                  lv_Vm.profileImg = imgUrl + lv_Vm.lv_ImgName + '&sno=' + timeStamp
                  lv_Vm.profileImgBtn = imgUrl + lv_Vm.lv_ImgName + '&sno=' + timeStamp
                  lv_Vm.selProfImgBtn = true
                  lv_Vm.baseProfImgBtn = false
                }
              })
              lv_Vm.getStore('toast').dispatch('ADD', '사진이 업로드 되었습니다.')
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_UpdUserBaseImg
      * 설명       : 사용자 지정 프로필 저장 시, 이미지 타입 정보 저장(TABLE TTSZ00020)
      ******************************************************************************/
      fn_UpdUserBaseImg () {
        let lv_Vm = this
        let pParams = {
          selBaseType: '',
        }
        const trnstId = 'txTSSBC10U8'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) {
              console.log(response.body)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_SetBaseImage
      * 설명       : 컨설턴트 내사진 업로드 대신 기본이미지를 선택시
      *              서비스 호출 : 해당 서비스에서 기본이미지=컨설턴트 기본이미지로 설정
      *              sBaseImgType=02, sBaseImgType=03,  sBaseImgType=04
      ******************************************************************************/
      fn_SetBaseImage () {
        let lv_Vm = this
        let pParams = {
          selBaseType: this.sBaseImgType,
          imgData: this.base64Data
        }
        const trnstId = 'txTSSBC10U4'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) {
              if ( response.body.isExistsImage === 'Y' || process.env.NODE_ENV === 'local') {
                lv_Vm.$emit('confirmPopup', {type: 'BASE', imgSrc: lv_Vm.sBaseImgType})
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_ConfirmPop (param) {
        if (param === 'confirm') { // 기본 이미지 저장
          this.fn_GetBase64Data()
        } else if (param === 'user') {
          this.$emit('confirmPopup', {type: 'USER'})
        } else {
          this.$emit('confirmPopup', {type: ''})
        }
      },
      fn_GetBase64Data () {
        fetch(this.$refs.profileImgRef.src)
          .then((res) => res.blob())
          .then((blob) => {
            const reader = new FileReader()
            reader.onloadend = () => {
              this.base64Data = reader.result
              this.fn_SetBaseImage()
            }
            reader.readAsDataURL(blob)
          })
      }
    }
  }
</script>