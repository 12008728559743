/*
 * 업무구분: 공통
 * 화 면 명: MSPBC101M
 * 화면설명: FC 디지털 프로필 메인 관리
 * 작 성 일: 2023.
 * 작 성 자: 조견진
 */
<template>
  <ur-page-container class="msp msp-bc-wrap" type="subpage" :show-title="true" title="디지털 프로필 설정" :topButton="true"><!-- type-popup -->
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">  
      <div class="photo_wrap ty2" @click="fn_PopModify('POT','사진등록')">
        <img v-bind:src="profileImg" alt="" class="profile_img">
        <mo-button class="photo_add">사진등록</mo-button>
      </div>
    <!-- 1 prof -->
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 pr24 mt90">
        <ur-box-container alignV="start" componentid="" direction="column" class="home_profile--box pt90">
          <div class="profile_box_wrap">
            <div class="name_wrap">
              <h2 class="fs26rem fwb mb5">{{Iv_ProfMainInfo.name}}</h2>
              <span class="crTy-bk7">{{Iv_ProfMainInfo.deptNm}}</span>
            </div>
            <div class="mt30 mb20 fexTy3 align-item-start">              
              <p class="fs17rem mt0 mla0" v-html="this.Iv_ProfTitl_prt_title"></p> <!-- 한줄 소개-->
              <mo-button class="btn_profile_edit mt5" @click="fn_PopModify('PFT','수정')">수정</mo-button>
            </div>
            <ul class="info_wrap mb50">
              <li class="cp">{{Iv_ProfMainInfo.celno}}</li>
              <li class="mail">{{Iv_ProfMainInfo.email}}</li>
            </ul>
          </div>
          <div class=""></div>
        </ur-box-container>        
      </ur-box-container>

    <!-- 고지사항 kyungsook23.choi, SR번호 : ASR230900401 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
        <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3 mb15">
          <span class="ns-title mb0 fwb">{{ lv_PcdList[4].cdNm }}</span>
          <div class="right" v-if="(Iv_sel_NotiImgList.length > 0)" @click="fn_DeleteNotiImg()">
            <button class="text_btn">
              <mo-icon icon="msp-trash"></mo-icon><span>삭제</span>
            </button>
          </div>
          <!-- <mo-button v-show='pcdChecked[4] && (Iv_sel_NotiImgList.length > 0)' class="btn_profile_set" @click="fn_PopModify('TAG',lv_PcdList[4].cdNm)">삭제</mo-button> -->
          <!-- <mo-switch v-model="pcdChecked[4]" @input="fn_udtHyptCdUseYn(lv_PcdList[4].profHyptCd, pcdChecked[4])" small class="ns-switch"/> -->
        </ur-box-container>

        <ur-box-container v-show='(Iv_sel_NotiImgList.length > 0)' alignV="start" componentid="" direction="column" class="home_profile--box pt20 pb20">
          <picture class="career_bs_img bdTy11 w100 h100 mt4 mh200px bgcolor-3 fexTy1">
            <img v-if="$bizUtil.isEmpty(notiImgData)" src="@/assets/img/profile/img_noneImage.png" alt="" class="noneImage w40px h40px" @click="fn_InsertNotiImg()">
            <img v-else :src="notiImgData" alt="" :style="imgStyle"/>
            <!-- <img v-else :src="notiImgData" alt="" @click="fn_ChangeNotiImg()" :style="imgStyle"/> -->
          </picture>
        </ur-box-container>
        <ur-box-container v-show='(Iv_sel_NotiImgList.length === 0)' alignV="start" componentid="" direction="column" class="home_profile_nodata content-center">
          <span v-html="no_data_msg[4]"></span>
          
          <mo-button componentid="mo_button" color="primary" shape="border" size="medium" class="ns-btn-round mt10 white align_self-center" @click="fn_InsertNotiImg()">사진추가</mo-button>
        </ur-box-container>

      </ur-box-container>  
     
    <!-- 자격정보 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
        <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3 mb15">
          <span class="ns-title mb0 fwb">{{ lv_PcdList[0].cdNm }}</span>
          <mo-switch v-model="pcdChecked[0]" @input="fn_udtHyptCdUseYn(lv_PcdList[0].profHyptCd,pcdChecked[0])" small class="ns-switch"/>
        </ur-box-container>
        
        <ur-box-container v-show='pcdChecked[0] && (Iv_sel_LiceList.length > 0)' alignV="start" componentid="" direction="column" class="home_profile--box pt20 pb20">
          <ur-box-container v-for="item in Iv_sel_LiceList" :key='item.profHyptCd' alignV="start" componentid="" direction="column" class="grid3 img_list--box">  
                <div class='item' v-for="items in item.datalist" :key='items.profHyptSn' > 
                    <img v-bind:src="Iv_certImgs[items.profHyptSn-1]" alt="" class="license_img" >                                       
                </div>
          </ur-box-container>
        <p class="fs14rem crTy-bk7 mt20 mb0">*기준:가설 출력 인증마크</p>
        </ur-box-container>
        
        <ur-box-container v-show='pcdChecked[0] && (Iv_sel_LiceList.length === 0)'  alignV="start" componentid="" direction="column" class="home_profile_nodata align-item-center">
          <span v-html="no_data_msg[0]"></span>
          <span>(기준: 가설 출력 인증마크)</span>
        </ur-box-container>

      </ur-box-container>

    <!-- 전문서비스  -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
        <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3 mb15">
          <div class="">
            <span class="ns-title mb0 fwb">{{ lv_PcdList[2].cdNm }}</span>
            <mo-button v-show='pcdChecked[2] && (lv_sel_SerList.length > 0)' class="btn_profile_set ml8" @click="fn_PopModify('SVC',lv_PcdList[2].cdNm)">설정</mo-button>
          </div>
          <mo-switch v-model="pcdChecked[2]" @input="fn_udtHyptCdUseYn(lv_PcdList[2].profHyptCd,pcdChecked[2])" small class="ns-switch"/>
        </ur-box-container>
        <ur-box-container v-show='pcdChecked[2] && (lv_sel_SerList.length > 0)' alignV="start" componentid="" direction="column" class="img_list_pro--box" >
          <div v-for='(item,index) in lv_sel_SerList' :key='index' >        
            <div :class="(items.sno % 2)? 'item item-green': 'item'" v-for="items in item.datalist" :key='items.no' >                 
                <div class="img_box" >
                    <img :src="Iv_Ser_Icons[item.profHyptGrpCd.substr(4,5)+items.profHyptSn]" alt="" class="pro_img">
                </div>
                <div class="txt_box">
                  <span class="" v-html="items.profHyptTitl"></span>
                </div>
            </div>
          </div>       
        </ur-box-container>

        <ur-box-container v-show='pcdChecked[2] && (lv_sel_SerList.length === 0)' alignV="start" componentid="" direction="column" class="home_profile_nodata content-center">
          <span class="align_self-center" v-html="no_data_msg[1]"></span>
          <mo-button @click="fn_PopModify('SVC',lv_PcdList[2].cdNm)" componentid="mo_button" color="primary" shape="border" size="medium" class="ns-btn-round mt10 white align_self-center" >서비스추가</mo-button>
        </ur-box-container>

      </ur-box-container>

    <!-- 커리어스  -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
        <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3 mb15">
          <span class="ns-title mb0 fwb">{{ lv_PcdList[3].cdNm }}</span>
          <mo-switch v-model="pcdChecked[3]" @input="fn_udtHyptCdUseYn(lv_PcdList[3].profHyptCd,pcdChecked[3])" small class="ns-switch"/>
        </ur-box-container>

        <ur-box-container v-if='pcdChecked[3] && (Iv_sel_ImgList.length > 0)' alignV="start" componentid="" direction="column">
          <ur-box-container alignV="start" componentid="" direction="column" class="grid3 career_list--box">            
            <!-- <div v-for="(items, index) in Iv_sel_ImgList" :key='index'>
              <div v-for="item in items[0].datalist" :key='item.profHyptSn'>
                  <img :src="item.imgData" @click="fn_updCarrImg(item.profHyptSn, item.imgData)" alt="" class="career_img">
              </div>
            </div> -->
            <div v-for="(items, index) in carrsImgItems" :key='index' class="career_item">
              <img :src="items.imgData" @click="fn_updCarrImg(items.profHyptSn, items.imgData)" alt="" class="career_img">
            </div>
            <div class="none_image" v-if="carrsImgItems.length < 6 && carrsImgItems.length !== 0">
              <!-- <mo-button class="btn_profile_add" @click="fn_PopModify('CAR',lv_PcdList[3].cdNm)"><mo-icon icon="add">add</mo-icon></mo-button> -->
              <mo-button class="btn_profile_add" @click="fn_BottomAlert"><mo-icon icon="add">add</mo-icon></mo-button>
            </div>            
          </ur-box-container>
        </ur-box-container>

        <ur-box-container v-show='pcdChecked[3] && (carrsImgItems.length === 0)' alignV="start" componentid="" direction="column" class="home_profile_nodata content-center">
          <span v-html="no_data_msg[2]"></span>
          <mo-button componentid="mo_button" color="primary" shape="border" size="medium" class="ns-btn-round mt10 white align_self-center" @click="fn_BottomAlert">사진추가</mo-button>
        </ur-box-container>

      </ur-box-container>

      <!-- 해시태그 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50 mb100">
        <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3 mb10">
          <div class="">
            <span class="ns-title mb0 fwb">{{ lv_PcdList[1].cdNm }}</span>
            <mo-button v-show='pcdChecked[1] && (lv_sel_TagsList.length > 0)' class="btn_profile_set ml8" @click="fn_PopModify('TAG',lv_PcdList[1].cdNm)">설정</mo-button>
          </div>
          <mo-switch v-model="pcdChecked[1]" @input="fn_udtHyptCdUseYn(lv_PcdList[1].profHyptCd,pcdChecked[1])" small class="ns-switch"/>
        </ur-box-container>

        <ur-box-container v-show='pcdChecked[1] && (lv_selAll_TagsList.length > 0)' alignV="start" componentid="" direction="column" class="">
          <ur-box-container alignV="start" componentid="" direction="row" class="hash_list--box" >            
            <ur-box-container alignV="start" componentid="" direction="row" class="item_group">               
                <div v-for="items in lv_selAll_TagsList" :key='items.profHyptTitl' class='hash_item'>
                    <span>{{items.profHyptTitl}}</span>
              </div>
            </ur-box-container>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container v-show='pcdChecked[1] && (lv_sel_TagsList.length === 0)' alignV="start" componentid="" direction="column" class="home_profile_nodata content-center">
          <span class="align_self-center" v-html="no_data_msg[3]"></span>
          <mo-button componentid="mo_button" color="primary" shape="border" size="medium" class="ns-btn-round mt10 white align_self-center" @click="fn_PopModify('TAG',lv_PcdList[1].cdNm)">태그추가</mo-button>
        </ur-box-container>

      </ur-box-container>    

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
    <div class="relative-div">
      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_MoveToMWS">미리보기</mo-button>
    </div>
    </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPBC103P from '@/ui/bc/MSPBC103P' // 프로필 사진관리 pop
  import MSPBC105P from '@/ui/bc/MSPBC105P' // 해시태그 pop
  import MSPBC106P from '@/ui/bc/MSPBC106P' // 전문분야 pop
  import MSPBC107P from '@/ui/bc/MSPBC107P' // 한줄소개 pop
  import MSPBC108P from '@/ui/bc/MSPBC108P' // 커리어스 pop
  import testImg from '@/ui/cm/test'
  import moment from 'moment'
  import bcConstants from '@/config/constants/bcConstants.js'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC101M",  // this.constructor.extendOptions.name
    screenId: "MSPBC101M",
    components: {
      MSPBC103P,
      MSPBC105P,
      MSPBC106P,
      MSPBC107P,
      MSPBC108P,
      MspBottomSelect
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
        // 자격정보이미지 초기화 세팅 
        this.Iv_certImgs[0] = require('@/assets/img/profile/'+this.lv_LiceList[0].datalist[0].profHyptImgNm+'.png')
        this.Iv_certImgs[1] = require('@/assets/img/profile/'+this.lv_LiceList[0].datalist[2].profHyptImgNm+'.png')
        this.Iv_certImgs[2] = require('@/assets/img/profile/'+this.lv_LiceList[0].datalist[1].profHyptImgNm+'.png')
        this.Iv_certImgs[3] = require('@/assets/img/profile/'+this.lv_LiceList[0].datalist[3].profHyptImgNm+'.png')
        this.Iv_certImgs[4] = require('@/assets/img/profile/'+this.lv_LiceList[0].datalist[4].profHyptImgNm+'.png')
        this.Iv_certImgs[5] = require('@/assets/img/profile/'+this.lv_LiceList[0].datalist[5].profHyptImgNm+'.png')
        // 고객서비스 썸네일 이미지 초기화 세팅 
        this.Iv_Ser_Icons[11] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[0].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[12] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[1].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[13] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[2].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[14] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[3].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[15] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[4].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[16] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[5].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[17] = require('@/assets/img/profile/'+this.lv_SerList[0].datalist[6].profHyptImgNm+'.png')

        this.Iv_Ser_Icons[21] = require('@/assets/img/profile/'+this.lv_SerList[1].datalist[0].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[22] = require('@/assets/img/profile/'+this.lv_SerList[1].datalist[1].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[23] = require('@/assets/img/profile/'+this.lv_SerList[1].datalist[2].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[24] = require('@/assets/img/profile/'+this.lv_SerList[1].datalist[3].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[25] = require('@/assets/img/profile/'+this.lv_SerList[1].datalist[4].profHyptImgNm+'.png')
        this.Iv_Ser_Icons[26] = require('@/assets/img/profile/'+this.lv_SerList[1].datalist[5].profHyptImgNm+'.png')

        this.Iv_Ser_Icons[31] = require('@/assets/img/profile/'+this.lv_SerList[2].datalist[0].profHyptImgNm+'.png')
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기

      let tmp = this.$route.params
      console.log('##### DATA FROM MSPBC100M ======>', tmp)
      // 1. 상단 프로필기본정보 세팅 
      this.lv_UserVo.userEno = this.getStore('userInfo').getters.getUserInfo.userEno
      this.Iv_ProfMainInfo.name = this.getStore('userInfo').getters.getUserInfo.userNm
      this.Iv_ProfMainInfo.deptNm = this.getStore('userInfo').getters.getUserInfo.userDeptNm
      this.Iv_ProfMainInfo.celno = !this.$bizUtil.isEmpty(tmp.celno) && (tmp.celno.trim() !== '')? this.$bizUtil.telNoWithHyphen(tmp.celno) : ''
      this.Iv_ProfMainInfo.email = !this.$bizUtil.isEmpty(tmp.email) && (tmp.email.trim() !== '')? tmp.email : ''

      // 프로필 사진 정보 조회
      if (!this.$bizUtil.isEmpty(this.$bizUtil.digitProfImg)) {
        // this.profileImg = this.$bizUtil.digitProfImg // 미리보기용 이미지
        this.fn_GetProfImg()
      }
      // 2. 프로필 관리 데이터 요청 및 UI세팅 
      this.fn_init()
      let sUserEno = this.getStore('userInfo').getters.getUserInfo.userEno + '_noti'
      this.fn_getEncryptCall(sUserEno, 'userEno')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_UserVo: {userEno: ''} // 사용자정보
      , profileImg: require('@/assets/img/profile/ico_life.png')
      , userEno: this.getStore('userInfo').getters.getUserInfo.userId
      , profImgBaseType: ''
      , no_data_msg: ['디지털 프로필에 노출 할 자격정보가 없습니다.'
                      ,'전문서비스를 추가해주세요.'
                      ,'사진을 추가해서 <br/> 당신의 커리어를 보여주세요(최대6개)'
                      ,'설정된 해시태그가 없습니다.'
                      ,'출력한 모집자 등록증을 촬영하여 첨부해주세요.'
                      ]
      , Iv_ProfTitl: {cd:'0',hyptCdYn:'N',profHyptTitl:bcConstants.BC_HYPT_DEF_TITL} // 한줄소개
      , Iv_ProfTitl_prt_title: bcConstants.BC_HYPT_DEF_TITL
      , Iv_ProfMainInfo: {name:'',deptNm:'',celno:'', email:''} // 상단 프로필기본정보
      , Iv_ProfSVO:[]
      , pcdChecked: [false,false,false,false,false] // 컨설턴트 설정 정보 그룹 노출여부
      , lv_PcdList: bcConstants.BC_PCD_LIST   // 컨설턴트 설정 정보 그룹 코드 (CNSLT_HYPT_INFO_CD)
      , lv_selAll_TagsList: []
      , lv_sel_TagsList: [] // 컨설턴트 저장된 해시태그 List   
      , lv_sel_SerList: []  // 컨설턴트 저장된 고객서비스 List   
      , Iv_sel_LiceList: [] // 컨설턴트 저장된 자격정보 List
      , Iv_sel_ImgList: []  // 커리어스 저장된 이미지 List
      , Iv_sel_NotiImgList: []  // 고지사항 저장된 이미지 List kyungsook23.choi, SR번호 : ASR230900401
      , carrsImgItems: []
      , Iv_sel_carr_Sn: 0
      , lv_TagsList: bcConstants.BC_TAG_LIST  // 해시태그 List
      , lv_LiceList: bcConstants.BC_LIC_LIST  // 자격정보 List
      , lv_SerList: bcConstants.BC_SVC_LIST   // 고객서비스 List
      , Iv_Ser_Icons: [] // 고객서비스 icon(썸네일사용) 이미지
      , Iv_ImgList:[]
      , imgList:{}
      , objTmp:{}
      , Iv_certImgs: []
      , notiImgData: ''
      , tmpNotiImgData: ''
      , lv_isMobile: false
      , retEnData: '' 
      , fcUserInfo: ''
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      imgStyle() {
        let style = {};

        style['width'] = '100%';
        style['height'] = '100%';
        style['aspect-ratio'] = '1/1.4';

        return style;
      }        
    },
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_init
      * 설명       : 디지털프로필 관리 > 메인 조회 ,
      *             _HyptCd 값이 존재하는 경우 popup에서 등록/수정한 정보를 동기화 적용하기 위한 조회 호출  
      ******************************************************************************/
      fn_init(_HyptCd){
        let lv_Vm = this

        let userRole = window.vue.getStore('userInfo').getters.getUserInfo.userRoleList
        if (userRole.includes('N001') || userRole.includes('N110')) {
          lv_Vm.fcUserInfo = 'nonY'
        }

        let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId,profHyptInfoList:[{profHyptCd:''}]}
        let trnstId = 'txTSSBC10S6' // 관리 화면 전체 데이터 조회 
        let selHyptCd = ''
        if(_HyptCd !== undefined){
          selHyptCd = _HyptCd 
          trnstId = 'txTSSBC10S7'  // _HyptCd 가 존재하는 경우 hyptCd 기준정보만 조회 
          pParams.profHyptInfoList[0].profHyptCd = selHyptCd
        }
        console.log('selHyptCd='+selHyptCd + ', trnstId=' + trnstId)
        
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {            
            lv_Vm.Iv_ProfSVO = response.body 
            const profHyptInfoList = lv_Vm.Iv_ProfSVO.profHyptInfoList

            for(let i=0; i<profHyptInfoList.length; i++){
              const hyptCd = profHyptInfoList[i].profHyptCd
              const hyptCdYn = profHyptInfoList[i].profHyptCdUseYn
                  switch (hyptCd) {
                  case 'C00': // 한줄소개
                    lv_Vm.Iv_ProfTitl.cd = profHyptInfoList[i].grpList[0].datalist[0].profHyptSn
                    lv_Vm.Iv_ProfTitl.hyptCdYn = hyptCdYn
                    let titile = profHyptInfoList[i].grpList[0].datalist[0].profHyptTitl
                    lv_Vm.Iv_ProfTitl.profHyptTitl = profHyptInfoList[i].grpList[0].datalist[0].profHyptTitl
                    lv_Vm.Iv_ProfTitl_prt_title = titile.replaceAll( /(\n|\r\n)/g, '<br>')
                    if (lv_Vm.Iv_ProfTitl.hyptCdYn === 'N'){
                      lv_Vm.Iv_ProfTitl.profHyptTitl = bcConstants.BC_HYPT_DEF_TITL
                      lv_Vm.Iv_ProfTitl_prt_title = bcConstants.BC_HYPT_DEF_TITL
                    }
                    // 프로필 이미지
                    let profHyptImgNm = profHyptInfoList[i].grpList[0].datalist[0].profHyptImgNm
                    if (!lv_Vm.$bizUtil.isEmpty(profHyptImgNm.trim())) {
                      lv_Vm.profImgBaseType = profHyptImgNm.slice(profHyptImgNm.length - 2)
                    } else {
                      lv_Vm.profImgBaseType = ''
                    }
                    break
                  case 'C01': // 자격정보
                    if(hyptCdYn === 'Y'){
                      lv_Vm.pcdChecked[0] = true
                    }         
                    lv_Vm.Iv_sel_LiceList = profHyptInfoList[i].grpList.filter( item => item.profHyptGrpCd != hyptCd+'_0')
                    lv_Vm.fn_setCdNm(lv_Vm.Iv_sel_LiceList,hyptCd)          
                    break
                  case 'C02': //해시태그
                    if(hyptCdYn === 'Y'){
                      lv_Vm.pcdChecked[1] = true
                    }
                    lv_Vm.lv_sel_TagsList =  profHyptInfoList[i].grpList.filter( item => item.profHyptGrpCd != hyptCd+'_0')
                    lv_Vm.fn_setCdNm(lv_Vm.lv_sel_TagsList,hyptCd)
                    let arr = []
                    lv_Vm.lv_sel_TagsList.forEach(items => {
                      console.log('items.datalist='+items.datalist.length)
                      if(items.datalist.length > 0){
                        items.datalist.forEach(item =>{
                          arr.push(item)
                        })
                      }
                    })
                    lv_Vm.lv_selAll_TagsList = arr
                    // console.log('lv_Vm.lv_selAll_TagsList='+lv_Vm.lv_selAll_TagsList.length)
                    break
                  case 'C03': //고객서비스
                    if(hyptCdYn === 'Y'){
                      lv_Vm.pcdChecked[2] = true
                    }
                    lv_Vm.lv_sel_SerList = profHyptInfoList[i].grpList.filter( item => item.profHyptGrpCd != hyptCd+'_0')
                    lv_Vm.fn_setCdNm(lv_Vm.lv_sel_SerList,hyptCd)
                    break
                  case 'C04': //커리어스
                    if(hyptCdYn === 'Y'){
                      lv_Vm.pcdChecked[3] = true
                    }
                    lv_Vm.Iv_sel_ImgList = profHyptInfoList[i].grpList.filter( item => item.profHyptGrpCd != hyptCd+'_0')                                   
                    if(lv_Vm.Iv_sel_ImgList != null && lv_Vm.Iv_sel_ImgList.length > 0){
                      let imglist = lv_Vm.Iv_sel_ImgList[0].datalist.filter( item => item.profHyptImgNm.trim() != '' )
                      console.info('커리어스이미지' + imglist)
                      if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
                        let locHost = location.host
                        if (locHost.indexOf('imss') > -1) {
                          if (imglist.length !== 0) {
                            for(let i = 0; i < imglist.length; i++) {
                              imglist[i].imgData = 'https://itss.samsunglife.com:8410/' + imglist[i].imgData // 임시로 우회처리
                            }
                          }
                        }
                      }
                      lv_Vm.Iv_sel_ImgList[0].datalist =  (imglist==undefined?[]:imglist)
                      lv_Vm.carrsImgItems = (imglist==undefined?[]:imglist)
                    }                   
                    break
                  case 'C05': // 고지사항 kyungsook23.choi, SR번호 : ASR230900401
                    if(hyptCdYn === 'Y'){
                      lv_Vm.pcdChecked[4] = true
                    }
                    
                    let tmpImgData = ''
                    try {
                      tmpImgData = profHyptInfoList[i].grpList[0].datalist[0].imgData
                    } catch(e) {
                      console.log(e)
                    }
                    if(!lv_Vm.$bizUtil.isEmpty(profHyptInfoList[i].grpList[0].datalist[0].profHyptImgNm) && !lv_Vm.$bizUtil.isEmpty(tmpImgData)){  
                      let url = ''                    
                      if (process.env.NODE_ENV === 'local') {
                        url = 'http://localhost:1111/'
                      } else if (process.env.NODE_ENV === 'development') {
                        url = 'http://wb1.t.tss.samsunglife.kr:8830/'
                      }                   
                      lv_Vm.notiImgData = url + tmpImgData
                      lv_Vm.Iv_sel_NotiImgList = profHyptInfoList[i].grpList                      
                    }

                  default:
                    break
                } // end switch
            }
          })
          .catch(function (error) {
            window.vue.error(error)

            // 2024.01.24 donghun.you 수정시작
            if ( response?.msgComm?.msgDesc ) {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', error)
            }
            // 2024.01.24 donghun.you 수정종료
          })
      },
      fn_udtHyptCdUseYn(_hyptCd, _use){ // 설정 toggle (노출여부) 등록/수정
        let lv_Vm = this
        let fhyptCdYn = _use === true ? 'Y' : 'N'
        let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId,profHyptInfoList:[{profHyptCd:'',profHyptCdUseYn:''}]}
            pParams.profHyptInfoList[0].profHyptCd = _hyptCd
            pParams.profHyptInfoList[0].profHyptCdUseYn = fhyptCdYn         
        const trnstId = 'txTSSBC10U6'
        const auth = 'S'
        console.log('_use:'+_use)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            lv_Vm.Iv_ProfSVO = response.body 
            // const profHyptInfoList = lv_Vm.Iv_ProfSVO.profHyptInfoList
            let idx = _hyptCd.substr(2)
            // console.log('idx='+idx)
            if(fhyptCdYn == 'Y' && (idx > 0)){
              lv_Vm.pcdChecked[idx-1] = true
            }            
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_setCdNm(_arrlist,_hyptCd){ // 설정그룹별 SN코드 매핑 title정보 세팅 
        let difflist = []
        let sno = 0
        if(_hyptCd == 'C01'){
          difflist = this.lv_LiceList
        }else if(_hyptCd == 'C02'){
          difflist = this.lv_TagsList
        }else if(_hyptCd == 'C03'){
          difflist = this.lv_SerList
        }
        for(let j=0; j<_arrlist.length; j++){
          for(let i=0; i<difflist.length; i++){             
            if(difflist[i].profHyptGrpCd === _arrlist[j].profHyptGrpCd) {                
              for(let h=0; h<_arrlist[j].datalist.length; h++){
                for(let k=0; k<difflist[i].datalist.length; k++){                 
                  if(difflist[i].datalist[k].profHyptSn === _arrlist[j].datalist[h].profHyptSn) {
                      _arrlist[j].datalist[h].profHyptTitl = difflist[i].datalist[k].profHyptTitl
                      _arrlist[j].datalist[h].sno = sno
                      sno++
                      // console.log('>>>>>>' + _arrlist[j].datalist[h].profHyptTitl + ':' + _arrlist[j].profHyptGrpCd)
                  } // end if
                }
              }
            } // end if
          }
        }
      },
      fn_PopModify (_Type,_title) { //  POPUP 호출 메소드  
        let P_id
        let P_params = {}
        let P_title = _title
        switch (_Type) {
          case 'TAG': // 해시태그 설정 팝업
            P_id = MSPBC105P
            P_params = {datalist:this.lv_sel_TagsList}
            break
          case 'SVC': // 전문서비스 설정 팝업 
          P_id = MSPBC106P
          P_params = {datalist:this.lv_sel_SerList}
            break
          case 'PFT': // 한줄소개 설정 팝업
          P_id = MSPBC107P
          P_params = this.Iv_ProfTitl
            break
          case 'CAR': // 커리어스 이미지 등록 팝업
          P_id = MSPBC108P
          P_params = {grplist:this.Iv_sel_ImgList}
            break
          case 'POT': // 프로필 사진관리 호출
          P_id = MSPBC103P
          P_params = {profImgBaseType: this.profImgBaseType}
            break
          default:
            break
        } // end switch

        this.popups = this.$bottomModal.open(P_id, {
          properties: {
            title: P_title
            ,params:P_params
          },
          listeners: {
            confirmPopup: (pData) => {
              if (_Type === 'POT') {
                if (!this.$bizUtil.isEmpty(pData.type)) {
                  this.$bizUtil.selDigitProfImg().then(() => {
                    if (!this.$bizUtil.isEmpty(this.$bizUtil.digitProfImg)) {
                      // this.profileImg = this.$bizUtil.digitProfImg
                      this.fn_GetProfImg()
                    }
                    this.fn_init('C00') // 한줄소개, 기본이미지 저장 정보 조회
                  })
                }
              }
              this.$bottomModal.close(this.popups)            
            },
            cancelPopup: () => {
              this.$bottomModal.close(this.popups)
            },
            initDataPopup: (value) => {
              console.log("initDataPopup.value="+value)
              this.fn_init(value)
              this.$bottomModal.close(this.popups)
            }
          }
        },
        {
          properties: {
            closeBtn: true
          },
          listeners: {
            "before-close": (fn) => {
              if (_Type === 'POT') {
                this.fn_GetProfImg()
              }
              fn(this.popups)
            }
          }
        })
      },
      fn_updCarrImg (idx, imgData) {
        console.log('selected INDEX', idx)
        let P_params = {}
        P_params.datalist = []
        if ( (this.Iv_sel_ImgList.length > 0) && (this.Iv_sel_ImgList[0].datalist.length > 0) ){ 
          P_params.datalist = this.$bizUtil.cloneDeep(this.Iv_sel_ImgList[0].datalist)
        }
        P_params.profHyptSn = idx
        P_params.imgData = imgData
        this.lv_popups = this.$bottomModal.open(MSPBC108P, {
          properties: {
            title: '커리어스'
            ,params: P_params
          },
          listeners: {
            cancelPopup: () => {
              this.$bottomModal.close(this.lv_popups)
              this.$bottomModal.close(this.lv_AlertPop)
            },
            initDataPopup: (value) => {
              console.log("initDataPopup.value="+value)
              this.fn_init(value)
              this.$bottomModal.close(this.lv_popups)
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        },
        {
          properties: {
            closeBtn: true
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_BottomAlert
      * 설명       : 알림 팝업 호출
      ******************************************************************************/
      fn_BottomAlert () {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            // single: true,
            title: '사진 업로드 시 유의사항',
            content: '미승인 상품자료, 교육자료, 동의 안된 타인이 포함된 사진, 개인정보가 노출된 자료 등<br/> <strong style="color: #e2351f;">컴플라이언스에 위배되는 사진</strong>을 게시하여 문제가 발생하는 경우, <strong style="color: #e2351f;">컨설턴트의 책임</strong>이 따르므로 주의하시기 바랍니다.'
          },
          listeners: {
            onPopupConfirm: () => {
              let lv_profHyptSn = this.fn_setCarrImgIndex()
              let fileNm = this.getStore('userInfo').getters.getUserInfo.userEno + '_' + lv_profHyptSn
              if (this.$commonUtil.isMobile() && this.$commonUtil.getIsIOS()) { // ios일때는 암호화
                this.fn_getEncryptCall(fileNm, 'fileNm')
              } else {
                if (process.env.NODE_ENV === 'local') { // 업로드 후 이미지 조회(txTSSBC10S3) 한 뒤에 MSPBC108P
                  let imgData = ''
                  this.fn_updCarrImg(lv_profHyptSn, imgData)
                } else {
                  this.fn_SelImgFile(fileNm)
                }
              }
              // this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_getEncryptCall
      * 설명       : 아이폰인 경우 파일명 암호화 후 파일 업로드
      ******************************************************************************/
      async fn_getEncryptCall(_string, argValue) {
        let strlist = [_string]
        let enData =  await this.$commonUtil.getEncryptCall(this,strlist)

        if (argValue === 'fileNm') {
          let sUserNo = enData[0]
          this.fn_SelImgFile(sUserNo)
        } else {
          this.retEnData = enData[0]
        }
      },
      /******************************************************************************
      * Function명 : fn_selCarrImg
      * 설명       : 업로드한 커리어스 이미지 조회
      ******************************************************************************/
      fn_selCarrImg () {
        let lv_vm = this
        let lv_profHyptSn = this.fn_setCarrImgIndex()
        let pParams = {
          profHyptInfoList:[{
            profHyptCd: 'C04',
            grpList: [{
              datalist:[{
                profHyptSn: lv_profHyptSn
              }]
            }]
          }]
        }
        window.vue.post(lv_vm, pParams, 'txTSSBC10S5', 'S')
          .then((response) => {
            try {
              if (response && response.body) {
                let datalist = response.body.profHyptInfoList[0].grpList[0].datalist
                let imgData = datalist[0].imgData
                if (!lv_vm.$bizUtil.isEmpty(imgData)) {
                  if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
                    let locHost = location.host
                    if (locHost.indexOf('imss') > -1) {
                      imgData = 'https://itss.samsunglife.com:8410/' + imgData // 임시로 우회처리
                    }
                  }
                  lv_vm.fn_updCarrImg(lv_profHyptSn, imgData) // MSPBC108P 커리어스 사진 관리
                }
              }
            } catch (e) {
              window.vue.error(e)  
            }
          })
          .catch((error) => {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_setCarrImgIndex
      * 설명       : 새로 추가할 커리어스 이미지 순번 세팅
      ******************************************************************************/
      fn_setCarrImgIndex () {
        let lv_profHyptSn = 1
        if (this.Iv_sel_ImgList.length !== 0) {
          let lv_dataList = this.Iv_sel_ImgList[0].datalist
          let snArray = []
          if (lv_dataList.length > 0) {
            lv_dataList.forEach((item) => {
              snArray.push(String(item.profHyptSn))
            })
          }
          for (let i = 1; i < 7; i++) {
            if (snArray.indexOf(String(i)) === -1) {
              lv_profHyptSn = i
              break
            }
          }
          console.log(`커리어스 이미지 snoArray : ${snArray}, sno : ${lv_profHyptSn}`)
        }
        return lv_profHyptSn
      },
      /******************************************************************************
      * Function명 : fn_SelImgFile
      * 설명       : 이미지 선택 이벤트
      * Return     : void
      ******************************************************************************/
      fn_SelImgFile (fileNm) {
        let lv_Vm = this

        let pParam = {
          path: 'PROFILE_CNSLT_IMG_DIR', // 이미지 업로드 경로-프로퍼티 Key
          filename: fileNm, // 저장파일명 (ios일때는 암호화)
          rateX: 1,
          rateY: 1
        }        
        console.log('#### file NAME ====>', fileNm)
        window.vue.getStore('progress').dispatch('UPDATE', true)
        window.fdpbridge.exec('uploadImagePlugin', pParam, function (result) {
          if (result.data === '200' || result.data === 'ok') {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            lv_Vm.fn_selCarrImg() // 업로드한 이미지 조회
          } else {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            lv_Vm.getStore('toast').dispatch('ADD', '사진 업로드에 실패했습니다.')
          }
        }, function (result) {
          window.vue.getStore('progress').dispatch('UPDATE', false)
          console.log(result)
          if ( result.data === 'NOPERMISSION' ) {
            lv_Vm.getStore('toast').dispatch('ADD', '태블릿 내부 저장권한이 없습니다. 모바일영업시스템 초기구동시 권한설정에 동의 해주세요')
          }
        })
      },
      fn_setUrl () {
        let url = ''
        let locHost = location.host
        if (process.env.NODE_ENV === 'local') {
          url = 'http://localhost:8081/#/mobile?mdigitkey=' + this.lv_UserVo.userEno
        } else if (process.env.NODE_ENV === 'development') {
          if (locHost.indexOf('samsunglife.kr:8960') > -1) {
            url = 'http://wb1.t.tss.samsunglife.kr:8950/#/mobile?mdigitkey=' + this.lv_UserVo.userEno  // 개발
          }else{
            url = 'http://wb1.t.tss.samsunglife.kr:8850/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // ci 개발
          }
        } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
         if (locHost.indexOf('imss') > -1) {
            url = 'https://imws.samsunglife.com:8440/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // pjt 검증
          }else{
            url = 'https://vmws.samsunglife.com:8440/#/mobile?mdigitkey=' + this.lv_UserVo.userEno  // ci 검증
          }
        } else {
          url = 'https://mws.samsunglife.com:8440/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // 운영
        }
        return url
      },
      fn_MoveToMWS () {
        let url = this.fn_setUrl()
        console.log('mws url', url)
        // window.open(url, '_blank')
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: url}, () => {}, () => {})
        } else { // PC에서 작동
          window.open(url, '_blank')
        }
      },
      fn_GetProfImg () {
        let imgUrl = '/profimgdown.do?userEno='
        let timeStamp = moment(new Date().toISOString()).format('YYYYMMDDHHmmss').toString().split('').reverse().join('')
        if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
          let locHost = location.host
          if (locHost.indexOf('imss') > -1) {
            imgUrl = 'https://itss.samsunglife.com:8410' + imgUrl // 임시로 우회처리
          }
        }
        this.profileImg = imgUrl + this.userEno + '&sno=' + timeStamp// 미리보기용 이미지
      },
      /******************************************************************************
      * Function명 : fn_ChangeNotiImg
      * 설명       : 고지사항 이미지 변경 이벤트
      ******************************************************************************/
      fn_ChangeNotiImg () {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '',
            content: '이미지를 변경하시겠습니까?'
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_InsertNotiImg()
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_DeleteNotiImg
      * 설명       : 고지사항 이미지 삭제 이벤트
      ******************************************************************************/
      fn_DeleteNotiImg () {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '',
            content: '이미지를 삭제하시겠습니까?'
          },
          listeners: {
            onPopupConfirm: () => {
              this.tmpNotiImgData = ''
              this.notiImgData = ''
              this.Iv_sel_NotiImgList = []
              this.fn_ImageModify('D')              
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_InsertNotiImg
      * 설명       : 고지사항 이미지 업로드 이벤트
      ******************************************************************************/
      fn_InsertNotiImg () {
        const lv_Vm = this
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '사진 업로드 시 유의사항',
            content: '미승인 상품자료, 교육자료, 동의 안된 타인이 포함된 사진, 개인정보가 노출된 자료 등<br/> <strong style="color: #e2351f;">컴플라이언스에 위배되는 사진</strong>을 게시하여 문제가 발생하는 경우, <strong style="color: #e2351f;">컨설턴트의 책임</strong>이 따르므로 주의하시기 바랍니다.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              let userEno = this.getStore('userInfo').getters.getUserInfo.userEno
              lv_Vm.lv_isMobile = lv_Vm.getStore('deviceState').getters.getIsMobile
              
              if (lv_Vm.Iv_sel_NotiImgList.length === 0) {
                lv_Vm.fn_udtHyptCdUseYn(lv_Vm.lv_PcdList[4].profHyptCd, true)
              }

              // Local에서 PC 테스트
              if (!lv_Vm.lv_isMobile) {
                if (process.env.NODE_ENV !== 'production') {
                  if(!lv_Vm.$bizUtil.isEmpty(lv_Vm.tmpNotiImgData) && lv_Vm.tmpNotiImgData.length > 10) {
                    let curImg = lv_Vm.tmpNotiImgData.substr(lv_Vm.tmpNotiImgData.length - 10, 10)
                    let testAImg = testImg.license_a.substr(testImg.license_a.length - 10, 10)
                    console.log('fn_InsertNotiImg', curImg, testAImg)
                    // 동일 이미지 업로드 체크
                    if(curImg === testAImg){
                      lv_Vm.tmpNotiImgData = testImg.license_b
                    } else {
                      lv_Vm.tmpNotiImgData = testImg.license_a
                    }
                  } else {
                    lv_Vm.tmpNotiImgData = testImg.license_a
                  }

                  lv_Vm.notiImgData = lv_Vm.tmpNotiImgData
                  lv_Vm.fn_ImageUpload()
                }
                return
              }

              let sUserEno = userEno + '_noti'
              if (lv_Vm.$commonUtil.isMobile() && lv_Vm.$commonUtil.getIsIOS()) { // ios일때는 암호화
                sUserEno = lv_Vm.retEnData
                console.log('암호화된 사번', lv_Vm.retEnData)
              }

              let pParam = {
                path: 'PROFILE_CNSLT_IMG_DIR', // 이미지 업로드 경로-프로퍼티 Key
                rateX: 3,
                rateY: 4,
                filename: sUserEno // 저장파일명
              }

              window.vue.getStore('progress').dispatch('UPDATE', true)
              window.fdpbridge.exec('uploadImagePlugin', pParam, function (result) {
                if (result.data === '200' || result.data === 'ok') {
                  
                  window.vue.getStore('progress').dispatch('UPDATE', false)
                  lv_Vm.fn_ImageModify()
                } else {
                  window.vue.getStore('progress').dispatch('UPDATE', false)
                  lv_Vm.getStore('toast').dispatch('ADD', '사진 업로드에 실패했습니다.')
                }
              }, function (result) {
                window.vue.getStore('progress').dispatch('UPDATE', false)
                console.log(result)
                if ( result.data === 'NOPERMISSION' ) {
                  lv_Vm.getStore('toast').dispatch('ADD', '태블릿 내부 저장권한이 없습니다. 모바일영업시스템 초기구동시 권한설정에 동의 해주세요')
                }
              })
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /************************************************
       * @function fn_ImageUpload
       * @notice 고지사항 PC 테스트용 Insert / Update
      *************************************************/
      fn_ImageUpload () {
        let lv_Vm = this
        let pParams = {
          selBaseType: this.sBaseImgType,
          imgData: lv_Vm.notiImgData
        }
        const trnstId = 'txTSSBC10U9'
        const auth = 'U'
        window.vue.getStore('progress').dispatch('UPDATE', true)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) {
              if ( response.body.isExistsImage === 'Y') {
                window.vue.getStore('progress').dispatch('UPDATE', false)
                lv_Vm.getStore('toast').dispatch('ADD', '사진을 업로드 하였습니다.')
                lv_Vm.fn_ImageModify()
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /************************************************
       * @function fn_ImageModify
       * @notice 고지사항 DB Insert / Update / Delete
      *************************************************/
      fn_ImageModify (type = '') {
        console.log('fn_ImageModify >')
        let lv_Vm = this
        
        const trnstId = 'txTSSBC10U7' // 설정코드별 매핑리스트저장
        const auth = 'S'

        let pParams = { 
          profHyptCd: 'C05',           
          grpList: [
            { 
              profHyptGrpCd:'C05_0',
              datalist:[
                { 
                  profHyptSn:'0',
                  profHyptImgNm: this.getStore('userInfo').getters.getUserInfo.userEno + '_noti',
                  profHyptTitl: '고지사항',
                  profHyptCntnt: '고지사항',
                }
              ]
            }
          ] 
        }

        // 이미지 삭제 시, grpList 가 없으면 데이터 삭제, 사용여부만 확인
        if (type === 'D'){
          pParams = {
              profHyptCd: 'C05',
              grpList: []
          }
        }
        
        console.log('pParams >', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body) {
              lv_Vm.fn_init('C05')
              console.log(' *** 고지사항 DB등록 성공 *** ')
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          })
      },      
      /******************************************************************************
      * Function명 : fn_CallSaImageCreate
      * 설명       : 사용자 이미지 업로드 완료시  안내자료, 컨설턴트이미지 생성 서비스 호출
      * Return     :
      ******************************************************************************/
      fn_CallSaImageCreate () {
        let lv_Vm = this
        let lv_ImgName = this.getStore('userInfo').getters.getUserInfo.userId
        let pParams = {cnsltNo: lv_ImgName}
        const trnstId = 'txTSSSA22P5'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) {
              // [디지털 프로필] 이미지 조회
              lv_Vm.$bizUtil.selDigitProfImg().then(() => {
                if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.$bizUtil.digitProfImg)) {
                  console.log('#### MSPBC101M 이미지 재조회 완료 #### ')
                  let imgUrl = lv_Vm.fn_GetProfImg()
                  let timeStamp = moment(new Date().toISOString()).format('YYYYMMDDHHmmss').toString().split('').reverse().join('')
                  lv_Vm.profileImg = imgUrl + lv_ImgName + '&sno=' + timeStamp
                }
              })
              lv_Vm.getStore('toast').dispatch('ADD', '사진이 업로드 되었습니다.')
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_UpdUserBaseImg
      * 설명       : 사용자 지정 프로필 저장 시, 이미지 타입 정보 저장(TABLE TTSZ00020)
      ******************************************************************************/
      fn_UpdUserBaseImg () {
        let lv_Vm = this
        let pParams = {
          selBaseType: '',
        }
        const trnstId = 'txTSSBC10U8'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body ) {
              console.log(response.body)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
    }
  }
</script>
<style scoped>
</style>