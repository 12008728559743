/*
* 업무구분: 디지털프로필 > 관리 > POP
* 화면 명: MSPBC105P
* 화면 설명: 해시태그 등록/수정
*/
<template>
  <ur-page-container class="msp" type="popup" :show-title="true" title="해시태그" :topButton="true">
      <template v-slot:title class="">{{title}}</template>
      <div class="ns-btn-close" @click="fn_CancelPop" name="닫기"></div>

      <ur-box-container alignV="start" componentid="ur_box_container_010" direction="row" class="fexTy3  pb16 pl24 pr24 bdTN expand_bottomline fs16rem">
        <span><b><em class="em_normal--2 crTy-blue3">{{selCnt}}</em>개선택</b>(최대 6개)</span>
        <div class="fexTy1" @click="fn_isSelShow">
          <span>선택보기</span>
          <mo-switch v-model="isSelShow" small class="ns-switch ml10"/>
        </div>
      </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-bottom-sheet">
      <div class="full home_profile--bs">
    <!--  바텀 시트 컨텐츠 추가 영역 시작 -->
    <div class="content-area">

      <ur-box-container v-show=(!isSelShow) alignV="start" componentid="ur_box_container01" direction="column">
        <div v-for='(items,idx1) in lv_TagsList' :key='idx1' >
          <ur-box-container alignV="start" componentid="ur_box_container" direction="column" class="mt20">
            <p class="fs18rem fwb mb10 mt0">{{items.cdNm}}</p>
            <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-chk-box3">      
              <div class="ns-check msp-multi-check--jjh">    
                <div v-for="item in items.datalist" :key='item.profHyptSn'>
                    <mo-checkbox v-model="itemSellist" :value="items.profHyptGrpCd+item.profHyptSn" >{{item.profHyptTitl}}</mo-checkbox>
                </div>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </ur-box-container>

      <ur-box-container v-show='(isSelShow) && (itemSellist.length > 0)' ur-box-container alignV="start" componentid="" direction="column">
        <ur-box-container alignV="start" componentid="" direction="column">
          <ur-box-container alignV="start" componentid="" direction="column" class="img_list_pro--box">   
            <div class="ns-check msp-multi-check--jjh">        
              <div  v-for="items in itemSellist" :key='items.profHyptSn'>
                  <mo-checkbox v-model="itemSellist" :value="items">{{fn_getGrpSnTitle(items)}}</mo-checkbox>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container> 

      <ur-box-container v-show='(isSelShow) && (itemSellist.length === 0)' alignV="start" componentid="" direction="column" class="home_profile_nodata content-center">
        <span class="align_self-center mt60" >선택된 항목이 없습니다.</span>
      </ur-box-container>

    </div>
    <!-- 바텀 시트 컨텐츠 추가 영역 끝-->
      </div>
    </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelPop">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CallSave">저장</mo-button>
          </div>
        </ur-box-container>

  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import bcConstants from '@/config/constants/bcConstants.js'

  export default {
    name: "MSPBC105P",
    screenId: "MSPBC105P",
    components: { },
    data() {
      return {
        P_params: {}
        , lv_TagsList: bcConstants.BC_TAG_LIST  // 해시태그 List
        , defArr: bcConstants.BC_TAG_LIST
        , itemChecked: []
        , itemSellist: []
        , isSelShow: false
      };
    },
    modalProps: {
      full: false
    },
    props: {
      title: {
        type: String,
        default: "해시태그"
      },
      params: {
        type: Object,
        default: {}
      }
    },
    created() {
      this.fn_setMapData()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기      
    },
    computed: {
      selCnt: function(){        
        let ic = this.itemSellist.length
        if(ic > 6){
            this.getStore('toast').dispatch('ADD', '6개까지 등록가능합니다.')
            // console.log('befor:'+this.itemSellist.length)            
            this.itemSellist.splice(-1,1)
            ic = ic-1
            // console.log('after:'+this.itemSellist.length)
          }
        return ic
      }
    },
    methods: {
      fn_isSelShow(){
        this.isSelShow = (this.isSelShow == true)?false:true
      },
      fn_ConfirmPop() {
        this.$emit("initDataPopup", "C02");
      },
      fn_setMapData(){
        // 1. 기준정보 그룹목록 초기화 작업 
        for(let i=0; i < this.lv_TagsList.length; i++){
          let arr = []
          this.itemChecked.push(arr)
        }
        // 2. 설정저장된 값 매핑
        // console.log(">>>>>>>>params.datalist.length:"+this.params.datalist.length)
        this.P_params = this.params.datalist  // grplist
        for(let y=0; y < this.P_params.length; y++){
          let arr = []
          let val = {}
          let hyptGrpCd = this.P_params[y].profHyptGrpCd
          let grpIdx = hyptGrpCd.substr(4)

          for(let i=0; i < this.P_params[y].datalist.length; i++){          
            val = hyptGrpCd + this.P_params[y].datalist[i].profHyptSn
            arr.push(val) //  
            this.itemSellist.push(val)
          }          
          this.itemChecked[grpIdx-1] = arr
        }
      },
      fn_CancelPop() {
        // console.log(this.params)
        this.$emit("cancelPopup");
      },
       /* SN에 대응하는 title 가져오기 (단일건 처리)
         _grpSn : profHyptGrpCd + profHyptSn
      */
      fn_getGrpSnTitle(_grpSn){
        if(_grpSn == null || _grpSn.length < 6){  return '' }
        const defArr = this.defArr
        const grpCd = _grpSn.substr(0,5)
        const sn = _grpSn.substr(5,6)
        const grpT = defArr.find( item => item.profHyptGrpCd === grpCd)
        if(grpT == undefined){  return '' }
        const snT = grpT.datalist.find( item => item.profHyptSn === Number(sn))
        if(snT == undefined){ return '' }
          return snT.profHyptTitl
      },
      fn_CallSave(){ // 설정선택 등록/저장 요청 
        let lv_Vm = this
        const trnstId = 'txTSSBC10U7' // 설정코드별 매핑리스트저장
        const auth = 'S'
        let pParams = { profHyptCd: 'C02', grpList: [] }
        let seti = 0
        // console.log('this.itemSellist:'+this.itemSellist)
          if(this.itemSellist == null || this.itemSellist.length == undefined){
            console.log('itemSellist undefined!!!!!!!!!!!!!!!!!')
          }else{
            for(let i=0; i < this.itemSellist.length; i++){
              let grpCd = this.itemSellist[i].substr(0,5)
              //  console.log('grpCd:'+grpCd)
              let setGrpCd = {profHyptGrpCd:grpCd,datalist:[]}
              pParams.grpList.push(setGrpCd)
              let sn = this.itemSellist[i].substr(5,6)          
              let setSn = {profHyptSn:sn}
              pParams.grpList[seti].datalist.push(setSn)
              // console.log('grpCd:'+grpCd+', sn:'+sn)
              seti++
            }
          }
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            const Iv_ProfSVO = response.body             
            // console.log(Iv_ProfSVO.userEno);
            lv_Vm.getStore('toast').dispatch('ADD', '해시태그가 설정되었습니다.')
            lv_Vm.fn_ConfirmPop()
          })
          .catch(function (error) {
            window.vue.error(error)
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          })
      } // end fn_CallSave
    }
  };
</script>