/*
* 업무구분: 디지털프로필 > 관리 > 커리어스
* 화면 명: MSPBC108P
* 화면 설명: 커리어스 등록/수정
*/
<template>
  <ur-page-container class="msp" type="popup" :show-title="true" title="커리어스 사진관리" :topButton="true">
    <template v-slot:title>커리어스 사진관리</template>
    <div class="ns-btn-close" @click="fn_ClickCancel" name="닫기"></div>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-bottom-sheet">
      <div class="full home_profile--bs">
        <!--  바텀 시트 컨텐츠 추가 영역 시작 -->
        <div class="content-area">
          <div class="career_bs_img--box">
            <div class="career_bs_img">
              <img v-bind:src="carrImg" alt="">
            </div>          
          </div>
        <div class="user_info_message mt20">
          <mo-text-field v-model="carrTitle" maxlength="25" :rules="validateRuleName" class="" underline placeholder="어떤 사진인가요?" />
        </div>
        <ur-box-container alignV="start" componentid="" direction="row" class="fexJsCtTy3">
          <div class="user_info_message--cnt mt6">
            <span><em>{{carrTitle.length}}</em> / 25</span>
          </div>
        </ur-box-container>
        
        <div class="user_info_message mt20">
          <mo-text-area v-model="carrContents" maxlength="50" @focus="fn_ControlKeyPad" :rules="validateRule" class="pa12 crTy-bk1" rows="3" placeholder="설명을 추가해 주세요."/>
        </div>
        <ur-box-container alignV="start" componentid="" direction="row" class="fexJsCtTy3">
          <div class="user_info_message--cnt mt6">
            <span><em>{{carrContents.length}}</em> / 50</span>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="row">
          <div class="right" v-if="delImgBtn" @click="fn_DelCarImg()">
            <button class="text_btn">
              <mo-icon icon="msp-trash"></mo-icon><span>삭제</span>
            </button>
          </div>
        </ur-box-container>
      </div>
      </div>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickCancel">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CallSave('save')" :disabled="carrTitle.trim().length === 0 || carrContents.trim().length === 0">저장</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  export default {
    data() {
      return {
        foreignCustVo: {}
        ,P_params:{}
        ,carrsDataList: []
        ,profHyptSn: 0
        ,carrImg: require('@/assets/img/profile/ico_life.png')
        ,carrTitle: ''
        ,carrContents: ''
        ,delImgBtn: false
        ,validateRuleName: [
            (v) => !!v || '필수입력항목입니다.'
        ]
        ,validateRule: [
          (v) => !!v || '필수입력항목입니다.'
        ]
      };
    },
    modalProps: {
      full: false
    },
    props: {
      title: {
        type: String,
        default: "UIDEV003P"
      },
      params: {
        type: Object,
        default: {}
      }
    },
    created() {
      console.log('####### MSPBC108P =====>', this.params)
      if (!this.$bizUtil.isEmpty(this.params)) {
        this.carrsDataList = this.$bizUtil.cloneDeep(this.params.datalist)
        this.profHyptSn = this.params.profHyptSn
      }
    },
    mounted () {
      this.$bizUtil.insSrnLog('MSPBC100M')
      this.fn_setData()

    },
    computed: {},
    methods: {
      fn_setData () {
        if (this.carrsDataList.length > 0) {
          let dataList = this.carrsDataList.filter((item) => item.profHyptSn === this.profHyptSn)
          if (dataList.length > 0) {
            console.log('수정')
            this.carrTitle = dataList[0].profHyptTitl
            this.carrContents = dataList[0].profHyptCntnt
            this.delImgBtn = true
          }
          console.log('##### selected item ==========>', dataList)
        }
        if (!this.$bizUtil.isEmpty(this.params.imgData)) {
          this.carrImg = this.params.imgData
        }
      },
      fn_ConfirmPop() {
        this.$emit("initDataPopup", "C04");
      },
      fn_CallSave(type){ // 커리어스 저장/삭제 요청 
        let lv_Vm = this
        const trnstId = 'txTSSBC10U7' // 설정코드별 매핑리스트저장
        const auth = 'S'
        let pParams = { profHyptCd: 'C04', grpList: [] }
        let setGrpCd = {profHyptGrpCd:'C04_1',datalist:[]}
        pParams.grpList.push(setGrpCd)
        let lv_profHyptImgNm = ' '
        let pCarrTitle = ' '
        let pCarrContents = ' '
        if (type === 'save') {
          lv_profHyptImgNm = this.getStore('userInfo').getters.getUserInfo.userEno + '_' + this.profHyptSn
          pCarrTitle = this.carrTitle
          pCarrContents = this.carrContents
        }
        let setSnData = {profHyptSn:this.profHyptSn,profHyptImgNm: lv_profHyptImgNm // 이미지명(삭제: ' ', 저장: 이미지 파일명)
                        ,profHyptTitl:pCarrTitle, profHyptCntnt:pCarrContents}
        pParams.grpList[0].datalist.push(setSnData)
        console.log('lv_profHyptImgNm : ', lv_profHyptImgNm, 'pParams', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body) {
              let msg = (type === 'save')? '커리어스가 저장되었습니다.' : '삭제되었습니다.'
              lv_Vm.getStore('toast').dispatch('ADD', msg)
              lv_Vm.fn_ConfirmPop()
            }
            
          })
          .catch(function (error) {
            window.vue.error(error)
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          })
      }, // end fn_CallSave
      fn_ClickCancel() {
        console.log(this.P_params)
        this.$emit("cancelPopup");
      },
      fn_DelCarImg () {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '',
            content: '삭제하시겠습니까?'
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_CallSave('del')
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_ControlKeyPad
       * 설명       : 키보드 입력 시 화면을 키보드 위로 올라오게 처리하는 함수
       ******************************************************************************/
      fn_ControlKeyPad () {
        this.$commonUtil.setHiddenPlugin(false)
      }
    }
  };
</script>
<style scoped>
  [componentid="ur_box_container_008"] {
    height: 140px;
  }
</style>
<style lang="scss">
</style>